@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.navbar{
    display: flex;
    width:100%;
    height:80px;
    background: #652078;
    background-image: url("../../assets/Mask\ Group\ 1.png");
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:#fff;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 8px 16px #530E664D;
}

.navbar-logo{
    width: 225px;
    height: 80px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    -moz-border-radius:0 20px 20px 0;
    -webkit-border-radius:0 20px 20px 0;
    border-radius: 0 20px 20px 0;
}

.powered{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap:5px;
    padding-right: 20px;
    margin-bottom: 5px;
    background: #F1F1F1;
    height: 35px;
}

.powered > p{
    text-align: center;
    font-size: 14px;
    font-family: 'Roboto', sans-serif; ;
    letter-spacing: 1.12px;
    color: #000000;
}

.logout{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    font-family: 'Roboto', sans-serif;
    margin-right: 15px;
}

.tabCheck{
    padding: 15px;
    margin: 10px;
    border: 1px solid #000;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255);
    border-radius: 10px;
}

.checkTitle{
    padding: 10px;
    font-size: 1.5em;
    color: #652278;
}